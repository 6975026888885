import React from "react";

import '../css/tickets.css'

var BALL = 'https://qtickets.ru/event/152502'
var CODE = 'https://qtickets.ru/event/147812'
var ELYA = 'https://t.me/traphouserussia'
var DJ = 'https://t.me/controlschool'

function Button(props) {
  return(
    <a target={"_blank"} rel="noreferrer" href={props.link} className="ticketBtn">{props.text}</a>
  );
}

function Tickets() {
  return(
    <div className="containerTickets" id="ticket">
      <h1 className="ticketsToBuy">Чтобы купить билет</h1>
      <h1 className="ticketsChoise">Выбери мероприятие</h1>
      <div className="ticketsContainer">
        <Button link={BALL} text="Boyfriend Day"></Button>
        <Button link={CODE} text="Girlfiend Day"></Button>
        <Button link={ELYA} text="ELYAPLUGG"></Button>
        <Button link={DJ} text="DJ SCHOOL"></Button>
      </div>
      <p>*Если кнопка не работает, то билеты приобретаются на входе</p>
    </div>
  )
}

export default Tickets;