import './css/main.css'
import React from 'react';

import {
  Outlet,
  Route,
  Routes,
  Link
} from 'react-router-dom';

import Header from './pages/header';
import Slider from './pages/slider'
import Tickets from './pages/tickets';
import About from './pages/about'
import Social from './pages/social';
import Faq from './pages/faq';
import Artists from './pages/artists';
import Team from './pages/team'
import Gallery from './Gallery'

import Photo from './pages/photo'
import Video from './pages/video'

function Main() {
  return(
    <main>
      <Slider></Slider>
      <Tickets></Tickets>
      <About></About>
      <Social></Social>
      <Faq></Faq>
      <Artists></Artists>
      <Team></Team>
    </main>
  );
}

function GalleryPhoto() {
  return(
    <main>
      <Gallery/>
      <Photo/>
    </main>
  );
}
function GalleryVideo() {
  return(
    <main>
      <Gallery/>
      <Video/>
    </main>
  );
}

function Template() {
  return(
    <div>
      <Header/>
      <Outlet/>
      <div>
        <h1 className='copyrigtText'>© TrapHouse. Since 1990.</h1>
      </div>
    </div>
  );
}
function Error() {
  return(
    <div>
      <Header/>
      <h1 className='errorMessage'>Ошибка. Страница не найдена.</h1>
      <h2 className='errorTooltip'>Вернитесь на главную страницу, нажав <Link to='/'>Сюда</Link>.</h2>
    </div>
  );
}

function App() {
  return (
    <Routes>
      <Route path='/' element={<Template/>}>
        <Route path='/' element={<Main/>}/>
        <Route path='/gallery' element={<Gallery/>}/>
        <Route path='/gallery/photo' element={<GalleryPhoto/>}/>
        <Route path='/gallery/video' element={<GalleryVideo/>}/>
      </Route>
      <Route path="*" element={<Error/>} />
    </Routes>
  );
}

export default App;
